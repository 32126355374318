import React, {useState, useEffect} from 'react'
import { useSpring, animated } from 'react-spring'
import './App.css'
import MailchimpSubscribe from "react-mailchimp-subscribe"

const calc = (x, y) => [-x + window.innerWidth / 2, -y + window.innerHeight / 2]
const trans2 = (x, y) => `translate3d(${x / 9-100  }px,${y / 9 +50 }px,0)`
const transPeeps = (x, y) => `translate3d(${x / 5  }px,${y / 5 +350 }px,0)`
const car1 = (x, y) => `translate3d(${x / 8 + 200 }px,${y / 8 -200 }px,0)`
const car2 = (x, y) => `translate3d(${x / 7.5 -250 }px,${y / 7.5 -150 }px,0)`
const car3 = (x, y) => `translate3d(${x / 8.5 -250 }px,${y / 8.5 +50 }px,0)`

function App() {
  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))


  const [perspective, setPerspective] = useState(0);
  const [xRotation, setXRotation] = useState(20);
  const [blurAmount, setBlurAmount] = useState(0)
  const [showEmailForm, setShowEmailForm] = useState(true);

  // const [stageObjects, setStageObjects] = useState({
  // })

  const formUrl = "https://planetskill.us2.list-manage.com/subscribe/post?u=6a9427a92a1aea3fcf0c7e956&amp;id=821d2205c1";

  const handleKeyDown = (e) => {
    // console.debug(e.keyCode);
    switch (e.keyCode) {
      case 37:
        setPerspective(perspective - 1);
        break
      case 38:
        setXRotation(xRotation + 1)
        break
      case 39:
        setPerspective(perspective + 1);
        break
      case 40:
        setXRotation(xRotation - 1)
        break
      default:
        break
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    }
  })

  const CustomForm = ({status,message, onValidated}) => {
    let email, name;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
        // NAME: name.value
    });

    useEffect(() => {
      if (status === "success") {
        setTimeout(() => {
          setBlurAmount(0);
          setShowEmailForm(false);
        }, 2000);
      }
    }, [status])

      // console.log(status);
      const buttonMessages = {
        null: "SUBMIT",
        "sending": "PROCESSING",
        "success": "THANK YOU!",
        "error": "TRY AGAIN LATER...",
      }
      return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', position: 'absolute', top: 0, right: 0} } >
          <div className="emailForm" style={{opacity: (blurAmount / 20)}} onMouseOver={e => setBlurAmount(20)} onMouseLeave={e => setBlurAmount(0)}>
            {/* <input
              style={{ fontSize: "2em", padding: 5 }}
              ref={node => (name = node)}
              type="text"
              placeholder="Your name"

            /> */}
            <p style={{color: 'white', fontSize: "1.3em", textAlign: 'center'}}>STAY CONNECTED</p>
            <input
              style={{ fontSize: "1.3em", padding: 10, border: 'none', borderBottom: '1px solid #fff' }}
              ref={node => (email = node)}
              type="email"
              placeholder="email"
              disabled={status !== null}
            />
            <button style={{ fontSize: "1.3em", padding: 10, border: 'none', }} onClick={submit} disabled={status !== null}>
              {buttonMessages[status]}
            </button>
          </div>
        </div>
      )
  }

  const bg = (x, y) => `rotateX(${xRotation}deg) translate3d(${x / 20}px,${y / 20 }px,0)`
  return (
    <div style={{height: '100%', }}>
      <div id="bg-image" style={{filter: `blur(${blurAmount}px)`}}/>
      <div style={{position: 'absolute', right: 0, bottom: 0, padding: '6px', zIndex: 1}} >
        <a href="https://twitter.com/emgomusic" target='_blank' rel="noopener noreferrer" alt="EMGO twitter"><img className="social-icon-img" src={"/images/icons/t-icon.png"} alt="twitter icon" /></a>
        <a href="https://www.instagram.com/emgomusic" target='_blank' rel="noopener noreferrer" alt="EMGO instagram"><img className="social-icon-img" src="/images/icons/i-icon.png" alt="instagram icon"/></a>
        <a href="https://www.facebook.com/EMGOmusic" target='_blank' rel="noopener noreferrer" alt="EMGO Facebook"><img className="social-icon-img" src="/images/icons/f-icon.png" alt="facebook icon"/></a>
      </div>
      <div id="emgo-title">
        EVERYTHING MUST GO
      </div>
      {/* <div className="container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })} style={{perspective}}>
        <animated.div className="emgo-background card" style={{ transform: props.xy.interpolate(bg), perspective}} />
        <animated.div className="emgo-logo-building card" style={{ transform: props.xy.interpolate(trans2) }} />
        <animated.div className="peeps card" style={{ transform: props.xy.interpolate(transPeeps) }} />
        <animated.div className="car1 card" style={{ transform: props.xy.interpolate(car1) }} />
        <animated.div className="car3 card" style={{ transform: props.xy.interpolate(car3) }} />
        <animated.div className="car2 card" style={{ transform: props.xy.interpolate(car2) }} />
      </div> */}
      <MailchimpSubscribe
        url={formUrl}
        render={({subscribe, status, message}) => {
          return showEmailForm ? <CustomForm status={status} message={message} onValidated={formdata => subscribe(formdata)} /> : <></>
        }}
      />

    </div>
  );
}

export default App;
